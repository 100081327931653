.button-div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2em auto 1em
}

.button-87 {
    padding: 7px 25px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 100% auto;
    color: #562ABD;
    border-radius: 10px;
    display: block;
    border: 0px;
    font-weight: 700;
    box-shadow: 0px 0px 14px -7px #6c3f04;
    background-color: #fff;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.button-87:hover {
    background-color: transparent;
    border: 2px solid #fff;
    color: #fff;
}

.button-87:active {
    transform: scale(0.55);
    border: 2px solid #fff;
    color: #fff;
    background-color: transparent;
}

.contact-card {
    width: 35%;
    background-color: #562ABD;
    padding: 2em 0.7em;
    border-radius: 50px;
    color: #fff;
}

.contact-page-button {
    padding: 0.4em 0.7em;
    color: #fff;
    background-color: #562ABD;
    width: 50%;
    font-weight: 1000;
    font-size: 1.1em;
    border: 1.8px solid #562ABD;
    border-radius: 10px;
    transition: color 0.3s ease, background-color 0.3s ease;
    text-decoration: none;
    margin-top: 1.4em;
}

.form-control-1 {
    border: 1.3px solid #562ABD;
    padding: 1em;
    border-radius: 10px;
    width: 100%;
}

.errorMessage {
    color: rgb(176, 1, 1);
}

@keyframes buttonHover2 {
    0% {
        color: #fff;
        background-color: #562ABD;
    }
    50% {
        color: #562ABD;
        background-color: transparent;
    }
    100% {
        color: #562ABD;
        background-color: transparent;
    }
}

@keyframes buttonHover4 {
    0% {
        color: #210C6E;
        background-color: transparent;
    }
    50% {
        color: #fff;
        background-color: #210C6E;
    }
    100% {
        color: #fff;
        background-color: #210C6E;
    }
}

.contact-page-button:hover {
    animation: buttonHover2 0.6s ease forwards;
    transition-delay: 0.4s;
}

.contact-page-image {
    width: 19em;
    border-radius: 50px
}

.social-link-2 {
    border-radius: 50%;
    background-color: transparent;
    color: #210C6E;
    background-color: transparent;
    font-size: 1.8em;
    margin: 0 -0.1em;
    padding: 0.3em 0.4em;
}

.social-link-2:hover {
    animation: buttonHover4 0.6s ease forwards;
    transition-delay: 0.4s;
}

@media (max-width: 1000px) {
    .button-87 {
        font-size: 1em;
        width: 100%;
        padding: 6px 0.7em;
    }
    .contact-card {
        width: 75%;
    }
}

@media (max-width: 600px) {
    .contact-page-image {
        width: 65%;
    }
}