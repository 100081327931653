body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

::-moz-selection,
 ::selection {
    background-color: #067d6d;
    color: #fff;
}

::-moz-selection {
    background-color: #067d6d;
    color: #fff;
}

 ::-webkit-selection {
    background-color: #067d6d;
    color: #fff;
}

 ::selection {
    background-color: #067d6d;
    color: #fff;
}

::-webkit-scrollbar {
    width: 13px;
}


/* Track */

 ::-webkit-scrollbar-track {
    border-radius: 0px;
    background-color: #fff;
}


/* Handle */

 ::-webkit-scrollbar-thumb {
    background: #2bc47c;
    border-radius: 10px;
}


/* Handle on hover */

 ::-webkit-scrollbar-thumb:hover {
    background: #096139;
}