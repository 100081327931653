.logo {
    width: 50px;
    height: auto;
    margin-right: 10px;
}

.link-logo {
    width: 20px;
    height: auto;
    margin-right: 5px;
}

.navbar-nav .nav-link {
    margin-right: 20px;
}

.custom-navbar .nav-button {
    font-size: 14px;
    padding: 6px 12px;
    border-radius: 5px;
}

.custom-navbar .login-button {
    margin-right: 10px;
}

.custom-navbar .cart-button {
    background-color: #1f231f;
    color: white;
}

.custom-navbar .nav-link {
    font-size: 1.1em;
    color: #fff;
    transition: color 0.3s ease;
    font-weight: 800;
    margin: 0 2.5em 0 0;
}

.custom-navbar .nav-link:hover {
    color: #e1c293;
    text-decoration: none;
    font-weight: 700;
    font-size: 1.15em;
}

.ml-auto {
    padding: 0em 2em;
}

.social-link {
    padding: 0.3em 0.4em;
    border-radius: 50%;
    background-color: #e6e2dc;
    --tw-bg-opacity: 1;
    color: #3b2802;
    font-size: 1.35em;
    margin: 0 0.4em;
}

.social-link:hover {
    background-color: #3b2802;
    color: #e6e2dc;
}

.nav-links-1 {
    margin: 0 1em 0 3.7em;
}

.nav-links-2 {
    margin: 0 3.5em 0 1em;
}

.navLinkss {
    font-size: 1.05em;
    padding: 0.1em 0;
    opacity: 0.7;
    font-family: Maven Pro;
    width: 50%;
}

.navLinkss:hover {
    opacity: 1;
    font-weight: 1000;
}

@media (max-width: 900px) {
    .nav-links-1 {
        margin-top: 2em;
        margin-bottom: 2em;
    }
    .nav-links-2 {
        text-align: center;
        margin: 0;
    }
}